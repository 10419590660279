export enum WritingType {
  UNKNOWN,
  EXPANSION, // 扩写
  CONTINUATION, // 续写
  AIWriting, // AI写作
  TRANSLATE, // 翻译
  REWRITE, // 改写
  SUMMARIZE, // 总结
}

export const WritingTypeText: { [key in WritingType]: string } = {
  [WritingType.UNKNOWN]: '',
  [WritingType.EXPANSION]: 'expansion',
  [WritingType.CONTINUATION]: 'continuation',
  [WritingType.AIWriting]: 'ai_writing',
  [WritingType.TRANSLATE]: 'translate',
  [WritingType.REWRITE]: 'rewrite',
  [WritingType.SUMMARIZE]: 'summarize',
};

export enum LanguageType {
  ZH_CHS = 1, //  简体
  ZH_CHT = 2, //  繁体
  EN = 3, //  英语
}

export const LanguageText: { [key in LanguageType]: string } = {
  [LanguageType.ZH_CHS]: '简',
  [LanguageType.ZH_CHT]: '繁',
  [LanguageType.EN]: 'EN',
};

export const LanguageName: { [key in LanguageType]: string } = {
  [LanguageType.ZH_CHS]: 'Chinese',
  [LanguageType.ZH_CHT]: 'Traditional Chinese',
  [LanguageType.EN]: 'English',
};

export enum OutputStyle {
  UNSPECIFIED = 1,
  OFFICIAL_LETTER = 2,
  NOTES = 3,
  NOTIFICATION_DOCUMENT = 4,
  MEETING_DOCUMENT = 5,
  RECORD = 6,
}

export const LanguageList = ['English', '繁體', '简体'];

export const OutputStyleText: { [key in OutputStyle]: string } = {
  [OutputStyle.UNSPECIFIED]: 'Unspecified',
  [OutputStyle.OFFICIAL_LETTER]: 'Official Letter',
  [OutputStyle.NOTES]: 'Notes',
  [OutputStyle.NOTIFICATION_DOCUMENT]: 'Notification Document',
  [OutputStyle.MEETING_DOCUMENT]: 'Meeting Document',
  [OutputStyle.RECORD]: 'Record',
};

export enum AIWorkStatus {
  NOT_WORKING, // 未工作
  WORK_PENDING, // 工作等待中
  WORKING, // 工作中
  COMPLETED, //  工作结束
  CHATError, //  AI出错
}

// 工作流写作场景
export enum WorkFlowScene {
  SPEECH = 'Speech', // 演讲稿
  WORK_REPORT = 'WorkReport', // 工作总结
  POLICY_ADDRESS = 'Policy_Address', // 施政报告
  UNSPECIFIC = '', // 未指定
}

export const WorkFlowSceneList = ['SPEECH', 'SUMMARY', 'UNSPECIFIC'];
