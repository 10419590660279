import { ResultReport } from '@/type/copilot';

const GenerateParam = 'GENERATE_PARAM';

export const getGenerateParam = () => {
  const result = localStorage.getItem(GenerateParam);
  if (result && result.length > 0) {
    return JSON.parse(result);
  }
  return null;
};

export const setGenerateParam = (gp: ResultReport) => {
  const currentParam = getGenerateParam();
  const param = currentParam ? { ...currentParam, ...gp } : gp;
  localStorage.setItem(GenerateParam, JSON.stringify(param));
};

export const clearGenerateParam = () => {
  localStorage.removeItem(GenerateParam);
};
