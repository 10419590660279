import { useCallback, useContext, useMemo } from 'react';
import { useModel } from '@modern-js/runtime/model';
import { GlobalContext } from '@/routes/context';
import EditorStore from '@/store/editorStore';
import { AIWorkStatus, WritingType } from '@/type/ui';

const useControlAIWritingInput = () => {
  const [, { setWriteEssayParam, setWritingType, changeWorkStatus, setIsAIWork }] = useModel(EditorStore);
  const { lang = 'en-US' } = useContext(GlobalContext);

  const languageVal = useMemo(() => {
    if ('zh-CN' === lang) {
      return '简体';
    }
    if ('zh-HK' === lang) {
      return '繁體';
    }
    return 'English';
  }, [lang]);

  const open = useCallback(() => {
    setWriteEssayParam({ content: '', style: '', lang: languageVal, wordNum: 500, references: [], fileText: [], user_ref: '' });
    setWritingType(WritingType.AIWriting);
    changeWorkStatus?.(AIWorkStatus.NOT_WORKING);
    setIsAIWork?.(true);
  }, [setWriteEssayParam, setWritingType, changeWorkStatus, setIsAIWork, languageVal]);

  const close = useCallback(() => {
    setWritingType(WritingType.UNKNOWN);
    changeWorkStatus?.(AIWorkStatus.NOT_WORKING);
    setIsAIWork?.(false);
  }, [setWritingType, changeWorkStatus, setIsAIWork]);

  const setWritingSence = useCallback(
    (sence: string) => {
      setWriteEssayParam({
        sence,
      });
    },
    [setWriteEssayParam],
  );
  return {
    open,
    close,
    setWritingSence,
  };
};

export default useControlAIWritingInput;
